import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Information about Data Migrators`}</p>
    </PageDescription>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Attribute`}</p></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Value`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Legal name`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Data Migrators Pty Ltd`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Registered address`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`c/o Rubiix, L10, 50 Queen Street, Melbourne VIC 3000, Australia`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Type`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Australian Proprietary Company, Limited By Shares`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`ASIC Registration Date`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`02 Sep 2011`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`ABN`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`44 153 028 829`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`ACN`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`153 028 829`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Telephone`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`+61 1300 328264`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Fax`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`+61 1300 867579`}</p></td></tr></tbody></table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      